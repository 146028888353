import { Maybe, parseBoolean } from 'lunr-core/browser'

export interface BrowserConfiguration {
  API_KEY:string
  SITE_URL:string
  FIREBASE_API_KEY:string
  FIREBASE_AUTH_DOMAIN:string
  FIREBASE_PROJECT_ID:string
  FIREBASE_STORAGE_BUCKET:string
  FIREBASE_MESSAGING_SENDER_ID:string
  FIREBASE_APP_ID:string
  FIREBASE_MEASUREMENT_ID:string
  FIREBASE_EMAIL_VERIFICATION_REQUIRED:boolean
  GOOGLE_TAG_MANAGER_CONTAINER:Maybe<string>
  RECAPTCHA_SITE_KEY:Maybe<string>
  FORCE_HTTPS:boolean
  SECURE_COOKIES:boolean
  CODAT_INVITE_URL:string
  ALLOWED_MIME_TYPES:string[]
  MAXIMUM_FILE_SIZE_IN_BYTES:number
}

let _config:Maybe<BrowserConfiguration> = null

export const getBrowserConfiguration = (): BrowserConfiguration => {
  if (!!_config) {
    return _config
  }

  if (!process.env.NEXT_PUBLIC_API_KEY) {
    throw new Error('NEXT_PUBLIC_API_KEY not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_SITE_URL) {
    throw new Error('NEXT_PUBLIC_SITE_URL not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_API_KEY) {
    throw new Error('NEXT_PUBLIC_FIREBASE_API_KEY not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN) {
    throw new Error('NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID) {
    throw new Error('NEXT_PUBLIC_FIREBASE_PROJECT_ID not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET) {
    throw new Error('NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID) {
    throw new Error('NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_APP_ID) {
    throw new Error('NEXT_PUBLIC_FIREBASE_APP_ID not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID) {
    throw new Error('NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_FIREBASE_EMAIL_VERIFICATION_REQUIRED) {
    throw new Error('NEXT_PUBLIC_FIREBASE_EMAIL_VERIFICATION_REQUIRED not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
    throw new Error('NEXT_PUBLIC_RECAPTCHA_SITE_KEY not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_CODAT_INVITE_URL) {
    throw new Error('NEXT_PUBLIC_CODAT_INVITE_URL not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_ALLOWED_MIME_TYPES) {
    throw new Error('NEXT_PUBLIC_ALLOWED_MIME_TYPES not set in process.env!')
  }

  if (!process.env.NEXT_PUBLIC_MAXIMUM_FILE_SIZE_IN_BYTES) {
    throw new Error('NEXT_PUBLIC_MAXIMUM_FILE_SIZE_IN_BYTES not set in process.env!')
  }

  const values:BrowserConfiguration = {
    API_KEY: process.env.NEXT_PUBLIC_API_KEY,
    SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    FIREBASE_EMAIL_VERIFICATION_REQUIRED: parseBoolean(process.env.NEXT_PUBLIC_FIREBASE_EMAIL_VERIFICATION_REQUIRED),
    GOOGLE_TAG_MANAGER_CONTAINER: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER,
    RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    FORCE_HTTPS: parseBoolean(process.env.NEXT_PUBLIC_FORCE_HTTPS),
    SECURE_COOKIES: parseBoolean(process.env.NEXT_PUBLIC_SECURE_COOKIES),
    CODAT_INVITE_URL: process.env.NEXT_PUBLIC_CODAT_INVITE_URL,
    ALLOWED_MIME_TYPES: process.env.NEXT_PUBLIC_ALLOWED_MIME_TYPES.split(','),
    MAXIMUM_FILE_SIZE_IN_BYTES: parseInt(process.env.NEXT_PUBLIC_MAXIMUM_FILE_SIZE_IN_BYTES)
  }

  if (isNaN(values.MAXIMUM_FILE_SIZE_IN_BYTES)) {
    throw new Error('process.env.NEXT_PUBLIC_MAXIMUM_FILE_SIZE_IN_BYTES is not a number!')
  }

  _config = values

  return _config
}