import cx from 'classnames'
import { LoadingSpinner, LoadingSpinnerSize } from 'lunr-core/browser'
import { ReactNode } from 'react'
import useIsNavigating from '../../next/useIsNavigating'

interface Props {
  children: ReactNode
}

export default (props:Props) => {
  const isNavigating:boolean = useIsNavigating()

  return (
    <div className={cx('App', isNavigating && 'App--navigating')}>
      {props.children}

      {isNavigating && (
        <div className='App--spinner'>
          <div className='full-height full-height-and-width'>
            <LoadingSpinner size={LoadingSpinnerSize.Small} />
          </div>
        </div>
      )}
    </div>
  )
}
